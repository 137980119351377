import * as React from "react";
import { Badge } from "react-bootstrap";
import { FaCodeBranch, FaDownload, FaStar } from "react-icons/fa";
import gitHubIcon from "../images/projects/github.svg";
import { GitHubRepository } from "../types";

const getLanguageColour = (language) => {
  switch (language.toLowerCase()) {
    case "php":
      return "#4F5D95";
    case "python":
      return "#3572A5";
    case "javascript":
      return "#F1E05A";
    default:
      return "#B07219";
  }
};

type Props = {
  repository: GitHubRepository;
};

const OpenSourcePackage: React.FC<Props> = ({ repository }) => {
  const { name, url, description, tags, language, stars, forks, downloads } =
    repository;

  return (
    <>
      <div className="mb-2">
        <img src={gitHubIcon} alt="" className="mr-1" width="20" />
        <a href={url} className="d-inline-block align-middle">
          <h5 className="mb-0">{name}</h5>
        </a>
      </div>
      <p>{description}</p>
      {tags && (
        <div className="d-none d-md-flex flex-wrap mb-3">
          {tags.map((tag, index) => (
            <Badge
              key={index}
              variant="light"
              className="px-2 py-2 mr-1 mb-1"
              style={{ fontSize: ".6rem" }}
            >
              {tag}
            </Badge>
          ))}
        </div>
      )}
      <div className="mb-3">
        <div className="mb-2">
          <div className="d-flex flex-column flex-md-row flex-wrap">
            <div className="mr-4 mb-1">
              <img
                src="https://avatars0.githubusercontent.com/u/1571743?v=4"
                alt=""
                className="rounded-circle img-fluid mr-2"
                width="30"
              />
              <span className="align-middle">matthewgoslett</span>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <span className="mr-3">
          <span
            style={{
              backgroundColor: getLanguageColour(language),
              borderRadius: "50%",
              width: "12px",
              height: "12px",
              display: "inline-block",
            }}
          />{" "}
          {language}
        </span>
        {stars > 0 && (
          <span className="mr-3">
            <FaStar /> {stars}
          </span>
        )}
        {forks > 0 && (
          <span className="mr-3">
            <FaCodeBranch /> {forks}
          </span>
        )}
        {downloads > 0 && (
          <span className="mr-3">
            <FaDownload /> {downloads.toLocaleString()}
          </span>
        )}
      </div>
    </>
  );
};

export default OpenSourcePackage;
