import { graphql } from "gatsby";
import * as React from "react";
import OpenSourcePackage from "../../components/OpenSourcePackage";
import ProjectsPageLayout from "../../components/ProjectsPageLayout";

const ProjectsOpenSourcePage = ({ data }) => {
  return (
    <ProjectsPageLayout
      pageTitle="Matthew Goslett's Open Source Projects"
      metaDescription="Matthew Goslett is a passionate contributor to the open-source community. These are a collection of the projects and packages he's both created and contributed to."
    >
      <p>
        I'm passionate about the open-source community and have been fortunate
        to work for employers who share my passion. These are some of the
        projects and packages I've created and contributed to over the years.
      </p>
      <hr />
      {data.allGitHubRepository.edges.map(({ node }, index) => {
        return (
          <React.Fragment key={index}>
            <OpenSourcePackage repository={node} />
            <hr className="my-4" />
          </React.Fragment>
        );
      })}
    </ProjectsPageLayout>
  );
};

export const query = graphql`
  query GitHubRepositoryQuery {
    allGitHubRepository(sort: { fields: downloads, order: DESC }) {
      edges {
        node {
          name
          url
          description
          language
          tags
          downloads
          forks
          stars
        }
      }
    }
  }
`;

export default ProjectsOpenSourcePage;
